<template>
  <div class="layout-config h-100 p-20">
    <h3 class="b-b-1 m-b-10">{{ $route.query.id ? '编辑' : '新增' }}研学活动</h3>
    <Form ref="form" :model="formItem" :rules="rules" :label-width="100" label-colon>
        <Row>
            <Col span="8">
                <FormItem label="研学活动名称" prop="activityName">
                    <Input v-model="formItem.activityName" placeholder="请输入研学活动名称" clearable />
                </FormItem>
                <!-- <FormItem label="参与人数">
                    <Input v-model="formItem.num" placeholder="请输入同行参与人数" clearable />
                </FormItem> -->
                <FormItem label="活动起止时间" v-if="!isTemp">
                    <RangeDataPicker :value="formItem" keys="startTime-endTime" type="datetimerange" placeholder="活动开始时间-活动截止时间" />
                </FormItem>
                <FormItem label="研学课程" v-if="!isTemp">
                    <GeoFile v-model="formItem.researchCourse" text="研学课程" upload-txt="上传课程" :course="true" :param="{ folderId: 2164, userId: $store.state.user.userId }" />
                </FormItem>
                <FormItem label="是否公开" prop="isPublic" v-if="!isTemp">
                    <RadioGroup v-model="formItem.isPublic">
                        <Radio :label="1">是</Radio>
                        <Radio :label="0">否</Radio>
                    </RadioGroup>
                </FormItem>
            </Col>
            <Col span="12" v-if="!isTemp">
                <FormItem label="同行人">
                    <Select v-model="formItem.activityParticipantsId" multiple clearable filterable placeholder="请选择活动同行参与人员">
                        <Option v-for="(item, index) in userList" :key="index" :value="item.userId" :disabled="item.userId == userId">{{ `${item.userName}_${item.orgName ? item.orgName : ''}` }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="研学路线">
                    <SelectLine v-model="formItem.researchLines" placeholder="请选择所包含的研学路线" @change="getLines" isArray :multiple="true" label-in-value />
                </FormItem>
                <FormItem label="研学点">
                    <SelectPoint v-model="formItem.researchPoints" :researchPoints="formItem.researchPoints" placeholder="请选择所包含的研学点" isArray />
                </FormItem>
            </Col>
            <Col span="24" v-if="!isTemp">
                <FormItem label="活动简介" prop="activityDetail" class="w-100">
                    <WangEditor ref="editor" />
                </FormItem>
            </Col>
        </Row>
        <FormItem>
            <Button type="success" @click="saveData">提交</Button>
            <Button type="default" class="m-l-5" @click="goBack">返回</Button>
        </FormItem>
    </Form>
  </div>
</template>

<script>
import { getDataById, addData, addTempData, updateData } from '@/libs/api/activity'
import { getUserAll } from '@/libs/api/base'
import { getPointsByLineId } from '@/libs/api/trace'
import { dict } from '@/config'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            dict,
            formItem: {},
            rules: {},
            userList: [],
            isTemp: false
        }
    },
    computed: {
        ...mapState({
            organization: state => state.user.organization,
            userId: state => state.user.userId,
            user: state => state.user
        })
    },
    methods: {
        // 根据ID获取研学线上的研学点
        async getPoints(lineId) {
            if(lineId) {
                const { code, data } = await getPointsByLineId({ researchLineId: lineId })
                if(code == "HA0200") {
                    if(data && data.length) {
                        let researchPoints = data.map(item => item.researchPointId)
                        // 一个研学点
                        // Object.assign(this.formItem, { researchPoints })
                        // 多个研学点
                        if(this.formItem.researchPoints)
                            this.formItem.researchPoints = Array.from(new Set([ ...this.formItem.researchPoints, ...researchPoints ]))
                        else 
                            this.formItem.researchPoints = [ ...researchPoints ] 
                        this.$forceUpdate()
                    }
                }
            } else {
                Object.assign(this.formItem, { researchPoints: null })
                this.researchPoints = []
            }
        },
        getLines(lines) {
            Object.assign(this.formItem, { researchPoints: null })
            if(lines.length) {
                lines.map(item => this.getPoints(item))
            }
        },

        // 保存数据
        saveData() {
            this.$refs.form.validate(valid => {
                if(valid) {
                    if(!this.isTemp) {
                        // 活动详情
                        let content = this.$refs.editor.getHtml()
                        content == '<p><br></p>' ? this.formItem.activityDetail = null : this.formItem.activityDetail = content
                    }
                    // 
                    // this.formItem.organizationId = this.organization.organizationId
                    this.formItem.ownerId = this.userId
                    // TODO: 同行人与参与人身份两个字段若有用户重复，返回的参与活动列表同用户下则有重复数据
                    // this.formItem.activityPeersId = this.formItem.activityParticipantsId
                    if(this.formItem.activityId) {
                        // 修改API
                        updateData(this.formItem).then(res => {
                            this.successResult(res, '编辑成功!')
                        });
                    } else {
                        if(this.isTemp) {
                            // 临时活动
                            addTempData({
                                title: this.formItem.activityName,
                                info: "",
                                isPublic: false,
                                learnBaseId: "",
                            }).then(res => {
                                this.successResult(res, '添加成功!')
                            })
                        } else {
                            // 新增API
                            addData(this.formItem).then(res => {
                                this.successResult(res, '添加成功!')
                            });
                        }
                    }
                }
            })
        },
        
        // 新增修改成功回调
        successResult(res, descMsg) {
            if (res.code == "HA0200") {
                this.$Message.success(descMsg || res.msg);
                this.goBack();
            }
            else {
                this.$Message.error(res.msg);
            }
        },
        // 返回列表
        goBack() {
            this.$router.push('/config/dataManage/activity')
        },

        // 获取用户列表
        async getUserList() {
            this.userList = await getUserAll()
        }

    },
    async created() {
        // 判断用户是否是游客
        this.isTemp = this.user.roleCode.includes("tourist")
        if(this.isTemp) this.formItem.isPublic = 1
        else this.getUserList()

        const { id } = this.$route.query
        if(id) {
            const payload = await getDataById({ activity_id: id })
            if(payload.code == "HA0200") {
                this.$refs.editor.setHtml(payload.data.activityDetail)
                this.formItem = payload.data
                Object.assign(this.formItem, {
                    activityParticipantsId: payload.data.activityParticipants.map(item => item.userId),
                    isPublic: payload.data.isPublic == true ? 1: (payload.data.isPublic == false ? 0 : null)
                })
            } else {
                this.$Message.error(payload.msg)
            }
        } else {
            this.formItem = {}
        }
    }
}
</script>

<style lang="less" scoped>
.layout-config {
    overflow-y: scroll;
}
</style>